import { type Address } from 'modules/geo/api/search';
import React from 'react';
import axios from 'redaxios';
import Autocomplete from './Autocomplete';

export type AddressAutocompleteProps = Omit<React.ComponentProps<typeof Autocomplete>, 'children' | 'getOptionValue' | 'fetchFn' | 'onSelect'> & {
  onSelect: (option: Address) => void;
  countryIds?: string[];
};

const AddressAutocomplete = ({ nativeInputProps, countryIds, onSelect, onClear, ...props }: AddressAutocompleteProps) => {
  // const [address, setAddress] = React.useState<Awaited<ReturnType<typeof fetchOptions>>[number]>();
  const fetchOptions = async (searchTerm: string) => {
    const queryString = new URLSearchParams({
      q: searchTerm,
      limit: '10',
      ...(countryIds ? { countryIds: countryIds.join(',') } : {}),
    }).toString();

    const {
      data: { items: suggestions },
    } = await axios.post<{ items: Address[] }>(`/api/geo/search?${queryString}`);

    return suggestions;
  };

  return (
    <Autocomplete
      minCharThreshold={3} // API BAN
      fetchFn={fetchOptions}
      onSelect={(address) => {
        // setAddress(address);
        onSelect(address);
      }}
      getOptionValue={(option) => option.label}
      onClear={() => {
        // setAddress(undefined);
        onClear?.();
      }}
      nativeInputProps={{
        ...nativeInputProps,
        placeholder: nativeInputProps?.placeholder || 'Tapez ici votre adresse',
      }}
      {...props}
    />
  );
};

export default AddressAutocomplete;
