import { type UserRole } from './interfaces';

export const getBadgeFromRoles = (roles: UserRole[] = []): 'starter' | 'booster' | 'teambooster' | 'pro' | 'superadmin' | 'none' => {
  if (roles.includes('superadmin')) return 'superadmin';
  if (roles.includes('pro')) return 'pro';
  if (roles.includes('teambooster')) return 'teambooster';
  if (roles.includes('booster')) return 'booster';
  if (roles.includes('member')) return 'starter';
  return 'none';
};
