/* This example requires Tailwind CSS v2.0+ */
import useUser from 'modules/User/hooks/useUser';
import { MdAccessAlarm, MdArchive } from 'react-icons/md';

const NotMemberBanner = () => {
  const { hasPermission, protect, user } = useUser();

  if (hasPermission('aspiring') || hasPermission('member')) {
    return null;
  }

  if (hasPermission('archived')) {
    return (
      <div className="bg-yellow-600 text-base max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8 mb-3">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-yellow-800">
              <MdArchive className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p className="ml-3 font-medium text-white truncate">
              <span>Ton profil a été archivé. Si c’est une erreur, contacte-nous.</span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-pink-600 text-base max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8 mb-3">
      <div className="flex items-center justify-between flex-wrap">
        <div className="w-0 flex-1 flex items-center">
          <span className="flex p-2 rounded-lg bg-pink-800">
            <MdAccessAlarm className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <p className="ml-3 font-medium text-white truncate">
            {!user?.roles || user?.roles?.length === 0 ? (
              <span>Nous avons besoin de valider tes informations</span>
            ) : (
              <span>Profil en attente de validation</span>
            )}
          </p>
        </div>
        <div className="order-3 mt-2 shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
          <div className="flex items-center justify-center text-white">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md border border-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              onClick={protect('member')(() => {})}
            >
              Plus de détails
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotMemberBanner;
