import { useAuthModal } from 'modules/auth';
import { gql, useMutation } from 'modules/GraphQL';

const IMPERSONATE_USER = gql`
  mutation impersonateUser($input: impersonateUserInput!) {
    impersonateUser(input: $input) {
      item {
        _id
        roles
      }
    }
  }
`;

const useImpersonateUser = () => {
  const { session } = useAuthModal();
  const [impersonateUserRaw, { loading: impersonatingUser }] = useMutation(IMPERSONATE_USER);

  const impersonateUser = (userId: string) => async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    await impersonateUserRaw({ variables: { input: { userId } } });
    window.location.href = '/searched-profiles';
  };

  const disimpersonateUser = () => async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    await impersonateUserRaw({ variables: { input: { userId: null } } });
    window.location.href = '/admin/users';
  };

  return {
    impersonateUserRaw,
    impersonateUser,
    impersonatingUser,
    impersonating: session?.impersonating,
    disimpersonateUser,
    impersonatedUser: session?.user,
  };
};

export default useImpersonateUser;
