import { AsyncAutocomplete } from 'modules/form';
import { gql } from 'modules/GraphQL';
import React from 'react';

const LIST_OCCUPATIONS = gql`
  query Occupations($page: NonNegativeInt, $limit: NonNegativeInt, $q: String) {
    items: occupations(sort: "name", page: $page, limit: $limit, q: $q) {
      pageInfo {
        count
        page
        limit
      }
      items {
        _id
        name
      }
    }
  }
`;
type Occupation = {
  _id: string;
  name: string;
};

type SelectOccupationProps = Omit<React.ComponentProps<typeof AsyncAutocomplete>, 'labelField' | 'queryFn'>;

const SelectOccupation: React.FC<SelectOccupationProps> = ({ isMulti, onChange, ...props }) => {
  return (
    <AsyncAutocomplete
      queryFn={LIST_OCCUPATIONS}
      isMulti={isMulti}
      labelField="name"
      onChange={(selected: any) => {
        if (isMulti) {
          onChange(selected ? (selected || []).map((sel: Occupation) => sel._id) : []);
        } else {
          onChange(selected ? selected?._id : null);
        }
      }}
      {...props}
    />
  );
};

export default SelectOccupation;
