import { gql, useMutation, useQuery } from 'modules/GraphQL';

const SEARCHED_PROFILE_FRAGMENT = gql`
  fragment NotificationSearchedProfileFragment on SearchedProfile {
    _id
    name
    bounty
    createdBy {
      _id
      name
      title
      image
      isTop
    }
  }
`;

const AVAILABLE_PROFILE_FRAGMENT = gql`
  fragment NotificationAvailableProfileFragment on AvailableProfile {
    _id
    name
    bounty
    createdBy {
      _id
      name
      title
      image
      isTop
    }
  }
`;

const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    _id
    kind
    type
    objectId
    createdAt
    updatedAt
    viewedAt
    searchedProfile {
      ...NotificationSearchedProfileFragment
    }
    availableProfile {
      ...NotificationAvailableProfileFragment
    }
  }
  ${SEARCHED_PROFILE_FRAGMENT}
  ${AVAILABLE_PROFILE_FRAGMENT}
`;

const LIST_NOTIFICATIONS = gql`
  query Notifications($page: NonNegativeInt!, $limit: NonNegativeInt!, $filter: NotificationsFilterInput) {
    myNotifications(sort: "-updatedAt", page: $page, limit: $limit, filter: $filter) {
      items {
        ...NotificationFragment
      }
      pageInfo {
        count
        page
        limit
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($_id: ID!, $input: updateNotificationInput!) {
    updateNotification(_id: $_id, input: $input) {
      success
      code
      error
      item {
        ...NotificationFragment
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

const useNotificationCenter = ({ page, limit, filter }: any = {}) => {
  const shouldFetch = page !== undefined;
  const { loading, data, error } = useQuery(LIST_NOTIFICATIONS, { variables: { page, limit, filter }, skip: !shouldFetch });

  const [updateNotificationRaw, { loading: updatingNotification }] = useMutation(UPDATE_NOTIFICATION, {
    refetchQueries: shouldFetch ? [{ query: LIST_NOTIFICATIONS, variables: { page, limit, filter } }, 'Notifications'] : [],
  });

  const markNotificationAsViewed = async ({ _id }: { _id: string }) => {
    return updateNotificationRaw({ variables: { _id, input: { viewedAt: new Date().toISOString() } } });
  };

  return { markNotificationAsViewed, updatingNotification, loading, data, error };
};

export default useNotificationCenter;
