import classNames from 'classnames';
import React from 'react';

type ToggleProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  label: React.ReactNode;
  onChange: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  variant?: 'primary' | 'secondary';
};

const Toggle: React.FC<ToggleProps> = ({ label, variant = 'primary', onChange, className, ...inputProps }) => {
  return (
    <label className={classNames('inline-flex cursor-pointer items-center reset gap-2', className)}>
      <input type="checkbox" className="reset peer sr-only" onChange={(e) => onChange?.(e.target.checked, e)} {...inputProps} />
      <span
        className={classNames(
          "peer max-w-[44px] relative block h-6 w-16 min-w-16 rounded-full bg-gray-200 after:h-5 after:w-5 after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-['']  peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-light dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-primary-dark rtl:peer-checked:after:-translate-x-full",
          {
            'peer-checked:bg-primary': variant === 'primary',
            'peer-checked:bg-secondary': variant === 'secondary',
          }
        )}
      ></span>
      <span className="ml-3 !mt-0">{label}</span>
    </label>
  );
};

export default Toggle;
