'use client';

import useUser from 'modules/User/hooks/useUser';
import RolesPlanBadge from './RolesPlanBadge';

export type MyPlanBadgeProps = Omit<React.ComponentProps<typeof RolesPlanBadge>, 'roles'>;

const MyPlanBadge: React.FC<MyPlanBadgeProps> = (props) => {
  const { user } = useUser();
  return <RolesPlanBadge roles={user?.roles ?? []} {...props} />;
};

export default MyPlanBadge;
