'use client';

import { type UserRole } from 'modules/User/interfaces';
import { getBadgeFromRoles } from 'modules/User/utils';
import PlanBadge from './PlanBadge';

export type RolesPlanBadgeProps = Omit<React.ComponentProps<typeof PlanBadge>, 'type'> & {
  roles: UserRole[];
};

const RolesPlanBadge: React.FC<RolesPlanBadgeProps> = ({ roles, className, ...props }) => {
  return <PlanBadge type={getBadgeFromRoles(roles || [])} className={className} {...props} />;
};

export default RolesPlanBadge;
