import { Button } from '@/lib/components/ui/button';
import classNames from 'classnames';
import React from 'react';
import useImpersonateUser from '../hooks/useImpersonateUser';

type BannerImpersonateProps = React.HTMLAttributes<HTMLDivElement> & {
  // TODO
};

const BannerImpersonate: React.FC<BannerImpersonateProps> = ({ children, className, ...props }) => {
  const { impersonating, disimpersonateUser, impersonatedUser } = useImpersonateUser();
  if (!impersonating) {
    return null;
  }
  return (
    <div className={classNames('bg-red-700 text-white text-xs p-2 flex items-center justify-between', className)} {...props}>
      <span>
        Tu es connecté sur le compte de <strong>{impersonatedUser.name}</strong>
      </span>
      <Button size={'xs'} variant={'destructive'} onClick={disimpersonateUser()}>
        Revenir à mon compte
      </Button>
    </div>
  );
};

export default BannerImpersonate;
