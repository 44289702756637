import { ReloadIcon } from '@radix-ui/react-icons';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const loadingVariants = cva('inline-block animate-spin', {
  variants: {
    size: {
      xs: 'h-3 w-3',
      sm: 'h-4 w-4',
      md: 'h-6 w-6',
      lg: 'h-8 w-8',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

interface LoadingProps extends VariantProps<typeof loadingVariants> {}

export const Loading: React.FC<LoadingProps & React.ComponentProps<typeof ReloadIcon>> = ({ size, ...props }) => {
  return <ReloadIcon className={loadingVariants({ size })} {...props} />;
};
