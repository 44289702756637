import useArrayQueryState from '@/lib/hooks/useArrayQueryState';
import { useDebouncedEffect } from '@react-hookz/web';
import classNames from 'classnames';
import SelectOccupation from 'modules/App/data-components/SelectOccupation';
import SelectSector from 'modules/App/data-components/SelectSector';
import AddressAutocomplete from 'modules/form/components/AddressAutocomplete';
import SelectCountry from 'modules/geo/data-components/SelectCountry';
import { AreaSearch } from 'modules/geo/models/AreaSearch';
import useUser from 'modules/User/hooks/useUser';
import { useSearchParams } from 'next/navigation';
import { parseAsJson, parseAsString, useQueryState } from 'nuqs';
import React from 'react';
import { MdClose, MdEdit, MdSearch, MdUndo } from 'react-icons/md';

export interface SearchProps {
  label?: React.ReactNode;
  className?: string;
  placeholder?: string;
}

export const useSearchFilterQuery = () => {
  const { items: sectorIds, exists: sectorIdsExists } = useArrayQueryState('sectorIds', { keepQueryParam: true });
  const { items: occupationIds, exists: occupationIdsExists } = useArrayQueryState('occupationIds', { keepQueryParam: true });
  const { items: countryIds, exists: countryIdsExists } = useArrayQueryState('countryIds', { keepQueryParam: true });
  const [area] = useQueryState<AreaSearch['areas'][0]>('area', parseAsJson());
  const [q, setQ] = useQueryState<string>('q', parseAsString);
  const searchParams = useSearchParams();
  const areaExists = searchParams.has('area');
  const qExists = searchParams.has('q');

  const filter: any = {};

  const followAlongSearchParams = Object.fromEntries(
    Array.from(searchParams.entries()).filter(([key]) => ['sectorIds', 'occupationIds', 'countryIds', 'q', 'area'].includes(key))
  );

  const followAlongSearchParamsString = new URLSearchParams(followAlongSearchParams).toString();

  if (sectorIdsExists) {
    filter.sectorIds = sectorIds;
  }
  if (occupationIdsExists) {
    filter.occupationIds = occupationIds;
  }
  if (countryIdsExists) {
    filter.countryIds = countryIds;
  }
  if (areaExists) {
    filter.area = area;
  }
  if (qExists) {
    filter.q = q;
  }

  return { filter, setQ, followAlongSearchParamsString };
};

const SearchItems = ({ label, className, placeholder, ...props }: SearchProps & React.HTMLAttributes<HTMLDivElement>) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const searchParams = useSearchParams();
  const { user } = useUser();

  const [queryQ, setQueryQ] = useQueryState<string>('q', parseAsString.withDefault(''));
  const [q, setQ] = React.useState<string | null>(queryQ || null);
  const {
    items: sectorIds,
    exists: sectorIdsExists,
    set: setSectorIds,
    reset: resetSectorIds,
  } = useArrayQueryState('sectorIds', { keepQueryParam: true });
  const {
    items: occupationIds,
    exists: occupationIdsExists,
    set: setOccupationIds,
    reset: resetOccupationIds,
  } = useArrayQueryState('occupationIds', { keepQueryParam: true });
  const {
    items: countryIds,
    exists: countryIdsExists,
    set: setCountryIds,
    reset: resetCountryIds,
  } = useArrayQueryState('countryIds', { keepQueryParam: true });
  const [area, setArea] = useQueryState<AreaSearch['areas'][0]>('area', parseAsJson());
  const areaExists = searchParams.has('area');
  const resetArea = () => setArea({} as any);

  const handleChange = (event: any) => {
    event.preventDefault();
    setQ(event.target.value);
  };

  useDebouncedEffect(
    () => {
      setQueryQ(q);
    },
    [q],
    300
  );

  React.useEffect(() => {
    if (q === '' && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [q]);

  React.useEffect(() => {
    if (!!queryQ && inputRef.current && inputRef.current.value !== queryQ) {
      setQ(queryQ);
    }
  }, [queryQ]);

  return (
    <div className={classNames('form-control flex gap-2 flex-wrap flex-row', className)} {...props}>
      <div className="inline-flex w-fit gap-2 bordered items-center relative">
        <MdSearch className="h-5 w-5 absolute left-1" />
        <input
          ref={inputRef}
          type="text"
          placeholder={'"chef de projet", comptable …'}
          style={{ paddingLeft: '25px', paddingRight: '25px' }}
          onInput={handleChange}
          value={(q as string) || ''}
        />
        {q && (
          <button className="h-5 w-5 absolute right-1" onClick={() => setQ(null)}>
            <MdClose />
          </button>
        )}
      </div>
      <div
        className={classNames('border-r border-l px-2 flex items-center gap-1 border-dashed border-gray-300', {
          'border rounded-sm': !sectorIdsExists,
          'border-r border-l': sectorIdsExists,
          'border-primary': !sectorIdsExists && user?.sectorIds?.length > 0,
        })}
      >
        {sectorIdsExists ? (
          <>
            <SelectSector
              isMulti
              defaultValue={sectorIds}
              onChange={(selected: any) => setSectorIds(selected)}
              placeholder={'Secteurs'}
              isClearable
            />
            <button className="link" onClick={() => resetSectorIds()}>
              <MdUndo />
            </button>
          </>
        ) : (
          <div className="text-sm inline-flex gap-2 items-center">
            <span>Secteurs{`${user?.sectorIds?.length > 0 ? ` (${user?.sectorIds.length})` : ' (Tous)'}`}</span>
            <button className="link" onClick={() => setSectorIds(user?.sectorIds)}>
              <MdEdit />
            </button>
            <button className="link" onClick={() => setSectorIds([])}>
              <MdClose />
            </button>
          </div>
        )}
      </div>
      <div
        className={classNames('border-r border-l px-2 flex items-center gap-1 border-dashed border-gray-300', {
          'border rounded-sm': !occupationIdsExists,
          'border-r border-l': occupationIdsExists,
          'border-primary': !occupationIdsExists && user?.occupationIds?.length > 0,
        })}
      >
        {occupationIdsExists ? (
          <>
            <SelectOccupation
              isMulti
              defaultValue={occupationIds}
              onChange={(selected: any) => setOccupationIds(selected)}
              placeholder={'Métiers'}
              isClearable
            />
            <button className="link" onClick={() => resetOccupationIds()}>
              <MdUndo />
            </button>
          </>
        ) : (
          <div className="text-sm inline-flex gap-2 items-center">
            <span>Métiers{`${user?.occupationIds?.length > 0 ? ` (${user?.occupationIds.length})` : ' (Tous)'}`}</span>
            <button className="link" onClick={() => setOccupationIds(user?.occupationIds)}>
              <MdEdit />
            </button>
            <button className="link" onClick={() => setOccupationIds([])}>
              <MdClose />
            </button>
          </div>
        )}
      </div>
      <div
        className={classNames('border-r border-l px-2 flex items-center gap-1 border-dashed border-gray-300', {
          'border rounded-sm': !countryIdsExists,
          'border-r border-l': countryIdsExists,
          'border-primary': !countryIdsExists && user?.countryIds?.length > 0,
        })}
      >
        {countryIdsExists ? (
          <>
            <SelectCountry isMulti defaultValue={countryIds} onChange={(selected: any) => setCountryIds(selected)} placeholder={'Pays'} isClearable />
            <button className="link" onClick={() => resetCountryIds()}>
              <MdUndo />
            </button>
          </>
        ) : (
          <div className="text-sm inline-flex gap-2 items-center">
            <span>Pays{`${user?.countryIds?.length > 0 ? ` (${user?.countryIds.length})` : ''}`}</span>
            <button className="link" onClick={() => setCountryIds(user?.countryIds)}>
              <MdEdit />
            </button>
            <button className="link" onClick={() => setCountryIds([])}>
              <MdClose />
            </button>
          </div>
        )}
      </div>
      <div
        className={classNames('border-r border-l px-2 flex items-center gap-1 border-dashed border-gray-300', {
          'border rounded-sm': !area,
          'border-r border-l': area,
          'border-primary': !area && user?.areaSearch?.areas?.length > 0,
        })}
      >
        {areaExists ? (
          <div className="flex items-center gap-2 ">
            <AddressAutocomplete
              defaultValue={area?.name}
              onSelect={(val) => {
                setArea((prev) => ({ radius: 10, ...prev, name: val.label, geo: val.geometry }));
              }}
              disableFirstFetch={true}
            />
            <div className="text-sm">
              <input
                placeholder="Km autour"
                size={5}
                defaultValue={area?.radius || 10}
                onChange={(e) => {
                  const value = e.target.value;
                  if (!value) {
                    return;
                  }
                  setArea((prev) => ({ ...prev, radius: +value } as AreaSearch['areas'][0]));
                }}
              />{' '}
              km
            </div>
            <button className="link" onClick={() => setArea(null)}>
              <MdUndo />
            </button>
          </div>
        ) : (
          <div className="text-sm inline-flex gap-2 items-center">
            <span>Zones ({user?.areaSearch?.areas?.length || 0})</span>
            <button
              className="link"
              onClick={() =>
                setArea(
                  user?.areaSearch?.areas?.length >= 1
                    ? {
                        name: user?.areaSearch?.areas[0].name,
                        geo: user?.areaSearch?.areas[0].geo,
                        radius: 10, // or any default value you want
                      }
                    : {
                        radius: 10,
                        name: 'Paris',
                        geo: {
                          type: 'Point',
                          coordinates: [2.3522, 48.8566],
                        },
                      }
                )
              }
            >
              <MdEdit />
            </button>
            <button className="link" onClick={() => resetArea()}>
              <MdClose />
            </button>
          </div>
        )}
      </div>

      {/* <AsyncAutocomplete
        queryFn={LIST_OCCUPATIONS}
        defaultValue={occupationId}
        name="occupationId"
        placeholder="Métier"
        labelField={'name' as any}
        isClearable
        onChange={(selected: any) => setOccupationId(selected ? selected._id : null)}
      /> */}
    </div>
  );
};

export default SearchItems;
