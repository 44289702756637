import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';
import countries from '../data/countries.json';
import { getFlagEmoji } from '../utils';

type Country = (typeof countries)[0];

type SelectCountryProps = {
  defaultValue?: string[];
  onChange: (option: any) => void;
  className?: string;
  isMulti?: boolean;
  placeholder?: string;
  isClearable?: boolean;
};

const FAVORITE_COUNTRIES = ['FR', 'BE', 'CH', 'CA'];

const SelectCountry: React.FC<SelectCountryProps> = ({ isMulti = true, placeholder, defaultValue, onChange, className, ...props }) => {
  const favoriteCountriesOptions = countries.filter((country) => FAVORITE_COUNTRIES.includes(country._id));
  const otherCountriesOptions = countries.filter((country) => !FAVORITE_COUNTRIES.includes(country._id));
  const groupedOptions = [
    {
      label: 'Pays les plus courant',
      options: favoriteCountriesOptions,
    },
    {
      label: 'Tous les pays',
      options: otherCountriesOptions,
    },
  ];

  return (
    <Select
      classNamePrefix="select"
      value={countries.filter((country: any) => defaultValue?.includes(country._id))}
      isClearable={true}
      placeholder={placeholder || `Sélectionne un ${isMulti ? `(ou plusieurs)` : ''} pays`}
      isMulti={isMulti}
      isSearchable={true}
      getOptionLabel={(option: Country) => `${getFlagEmoji(option._id)} ${option._i18n.fr.name}`}
      getOptionValue={(option: Country) => option._id}
      options={groupedOptions}
      onChange={(selected: any) => {
        if (isMulti) {
          onChange(selected ? (selected || []).map((sel: Country) => sel._id) : []);
        } else {
          onChange(selected ? selected?._id : null);
        }
      }}
      classNames={{
        control: (_state) => 'react-select__control',
        input: (_state) => 'react-select__input',
      }}
      className={classNames('react-select__async-autocomplete min-w-[200px]', className)}
      {...props}
    />
  );
};

export default SelectCountry;
