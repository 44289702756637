'use client';

import classNames from 'classnames';

export default function PlanBadge({
  type,
  label,
  className,
  ...props
}: {
  type?: 'none' | 'pro' | 'starter' | 'booster' | 'teambooster' | 'superadmin';
  label?: string;
} & React.HTMLAttributes<HTMLSpanElement>) {
  let planLabel =
    label ||
    (type === 'superadmin'
      ? 'SuperAdmin'
      : type === 'pro'
      ? 'Pro'
      : type === 'teambooster'
      ? 'Team Booster'
      : type === 'booster'
      ? 'Booster'
      : type === 'starter'
      ? 'Membre'
      : '');

  if (type === 'none') {
    return null;
  }

  return (
    <span
      className={classNames('font-bold px-2 py-1 -rotate-3 inline-block shadow-sm whitespace-nowrap text-sm', className, {
        'bg-starter text-gray-50': type === 'starter',
        'bg-booster text-gray-50': type === 'booster',
        'bg-teambooster text-gray-50': type === 'teambooster',
        'bg-pro text-gray-50': type === 'pro',
        'bg-gray-500 text-white': type === 'superadmin',
      })}
      {...props}
    >
      {planLabel}
    </span>
  );
}
