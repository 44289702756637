import { Dialog, Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Banner from 'modules/App/components/Banner';
import NotMemberBanner from 'modules/App/components/NotMemberBanner';
import { signOut } from 'modules/auth';
import Avatar from 'modules/common/components/Avatar';
import useNotifier from 'modules/common/hooks/useNotifier';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import Toggle from 'modules/form/components/Toggle';
import { Link } from 'modules/i18n';
import MyPlanBadge from 'modules/Marketing/components/MyPlanBadge';
import NotificationCenter from 'modules/notification-center/data-components/NotificationCenter';
import useUser from 'modules/User/hooks/useUser';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import {
  MdManageSearch as HomeIcon,
  MdGroupAdd as IconAffiliate,
  MdPersonSearch as IconAvailableProfiles,
  MdEventAvailable as IconCalendar,
  MdSupervisedUserCircle as IconCandidate,
  MdPeople as IconCandidates,
  MdOutlineMenu as IconMenu,
  MdInfoOutline as IconMoreInfos,
  MdOutlineFactCheck as IconMyMissions,
  MdMoveToInbox as IconReceived,
  MdAssignment as IconRules,
  MdOutlineSend as IconSent,
  MdBuild as IconTools,
  MdGroups as IconUsers,
  MdCardMembership,
  MdFilePresent,
  MdReceipt,
  MdClose as XIcon,
} from 'react-icons/md';
import { SiGmail, SiSlack, SiWhatsapp } from 'react-icons/si';
import BannerImpersonate from '../../App/data-components/BannerImpersonate';
import { useSearchFilterQuery } from '../components/SearchItems';

export default function AsideLayout({ children }: any) {
  const [sidebarOpen, toggleSidebar] = useState(false);
  const { pathname, query, push } = useRouter();
  const { user, hasPermission, updateUser } = useUser();
  const { notify } = useNotifier();
  const { email, whatsappDorothee, whatsappMartin, siteName, slackUrl } = usePublicRuntimeConfig();
  const { followAlongSearchParamsString } = useSearchFilterQuery();

  const handleSignOut = async () => {
    try {
      await signOut({
        callbackUrl: `${window.location.origin}/fr`,
      });
      notify('success', 'Tu as été deconnecté');
    } catch (e: any) {
      notify('error', e.toString());
    }
  };

  const navigation = [
    {
      name: 'Communauté',
      links: [
        {
          name: 'Postes à pourvoir',
          href: `/searched-profiles${
            !pathname.includes('/searched-profiles') ? (followAlongSearchParamsString ? `?${followAlongSearchParamsString}` : '') : ''
          }`,
          icon: HomeIcon,
          current: pathname.includes('/searched-profiles'),
        },
        {
          name: 'CVThèque Off-Market',
          href: `/available-profiles${
            !pathname.includes('/available-profiles') ? (followAlongSearchParamsString ? `?${followAlongSearchParamsString}` : '') : ''
          }`,
          icon: IconAvailableProfiles,
          current: pathname.includes('/available-profiles'),
        },
        {
          name: 'Membres',
          href: `/members${!pathname.includes('/members') ? (followAlongSearchParamsString ? `?${followAlongSearchParamsString}` : '') : ''}`,
          icon: IconUsers,
          current: pathname.includes('/members'),
          hidden: false,
        },
        {
          name: 'Parrainage',
          href: '/affiliate',
          icon: IconAffiliate,
          current: pathname.includes('/affiliate'),
          badge: '',
        },
        {
          name: 'Calendrier',
          href: '/calendar',
          icon: IconCalendar,
          current: pathname.includes('/calendar'),
        },
      ],
    },
    {
      name: 'Mon vivier',
      links: [
        { name: 'Mes missions', href: '/my-missions', icon: IconMyMissions, current: pathname.endsWith('/my-missions') },
        {
          name: 'Mes candidats Off-Market',
          href: '/my-available-profiles',
          icon: IconCandidates,
          current: pathname.endsWith('/my-available-profiles'),
        },
        { name: 'Tous mes candidats', href: '/candidates', icon: IconCandidate, current: pathname.endsWith('/candidates') },
      ],
    },
    {
      name: 'Activité',
      links: [
        {
          name: 'Candidats reçus',
          href: '/contacts/received',
          icon: IconReceived,
          current: pathname === '/contacts/[direction]' && query.direction === 'received',
        },
        {
          name: 'Candidats recommandés',
          href: '/contacts/sent',
          icon: IconSent,
          current: pathname === '/contacts/[direction]' && query.direction === 'sent',
        },
        {
          name: 'Missions reçues',
          href: '/contacts/mission/received',
          icon: IconReceived,
          current: pathname === '/contacts/mission/[direction]' && query.direction === 'received',
        },
        {
          name: 'Missions proposées',
          href: '/contacts/mission/sent',
          icon: IconSent,
          current: pathname === '/contacts/mission/[direction]' && query.direction === 'sent',
        },
        {
          name: 'Mes factures',
          href: '/my-invoices',
          icon: MdReceipt,
          current: pathname.startsWith('/my-invoices'),
        },
        {
          name: 'Mon abonnement',
          href: '/user/subscription',
          icon: MdCardMembership,
          current: pathname.startsWith('/user/subscription'),
        },
      ],
    },
    {
      name: 'Comment ça marche',
      links: [
        { name: "Plus d'infos", href: '/', icon: IconMoreInfos, current: pathname.endsWith('/') },
        { name: 'Les règles', href: '/terms-of-use#rules', icon: IconRules, current: pathname.endsWith('/') },
      ],
    },
  ];

  if (hasPermission('superadmin')) {
    navigation.push({
      name: 'Admin',
      links: [
        {
          name: 'Users',
          href: '/admin/users',
          icon: IconUsers,
          current: pathname.includes('/admin/users'),
        },
        // {
        //   name: 'Prospects',
        //   href: '/admin/prospects',
        //   icon: IconUsers,
        //   current: pathname.includes('/admin/prospects'),
        // },
        {
          name: 'Contacts',
          href: '/admin/contacts',
          icon: IconUsers,
          current: pathname.includes('/admin/contacts'),
        },
        {
          name: 'Factures',
          href: '/admin/invoices',
          icon: MdFilePresent,
          current: pathname.includes('/admin/invoices'),
        },
        {
          name: 'Outils',
          href: '/admin/tools',
          icon: IconTools,
          current: pathname.includes('/admin/tools'),
        },
        {
          name: 'Évènements',
          href: '/admin/events',
          icon: IconCalendar,
          current: pathname.includes('/admin/events'),
        },
      ],
    });
  }

  const userNavigation = [
    { name: '👤 Profil', href: '/user/me' },
    { name: '🔔 Notifications', href: '/user/settings' },
    {
      name: '⚡ Intégrations',
      href: '/user/integrations',
    },
    // { name: 'Settings', href: '#' },
    { name: '👋 Déconnexion', onClick: handleSignOut },
  ];

  const openToSource = (
    <section className="bg-primary py-5">
      <h3 className="px-3 text-xs font-semibold text-gray-50 uppercase tracking-wider" id="desktop-teams-headline">
        Dispo ?
      </h3>
      <div className="flex items-center gap-2 text-xs">
        <Toggle
          variant="secondary"
          className="mx-3 font-bold"
          onChange={async (val) => {
            await updateUser({ openToSource: val });
            if (val) {
              notify('success', "Super, on va t'appeler dès qu'une mission te correspond");
            } else {
              notify('success', "C'est noté, merci");
            }
          }}
          label="Je suis dispo pour du sourcing en ce moment"
          defaultChecked={user?.openToSource}
        />
      </div>
      <small className="mx-3 mt-2 leading-tight block italic text-xs">
        {user?.openToSource
          ? "Super, on va t'appeler dès qu'une mission te correspond"
          : "Si tu te déclares dispo, on va t'appeler dès qu'une mission te correspond"}
      </small>
    </section>
  );

  const otherLinks = (
    <section className="bg-gray-100 py-5">
      <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="desktop-teams-headline">
        Communauté
      </h3>
      <div className="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
        <a
          href={slackUrl}
          className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
          target="_blank"
          rel="noreferrer"
        >
          <SiSlack className="text-primary mr-4" aria-hidden="true"></SiSlack>
          <span className="truncate">Rejoignez le Slack 🎉</span>
        </a>
      </div>
      <h3 className="mt-5 px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="desktop-teams-headline">
        Contact
      </h3>
      <div className="mt-1 space-y-1" role="group" aria-labelledby="desktop-teams-headline">
        <a
          href={whatsappMartin}
          className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
          target="_blank"
          rel="noreferrer"
        >
          <SiWhatsapp className="text-green-500 mr-4" aria-hidden="true"></SiWhatsapp>
          <span className="truncate">WhatsApp Martin (technique)</span>
        </a>
        <a
          href={whatsappDorothee}
          className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
          target="_blank"
          rel="noreferrer"
        >
          <SiWhatsapp className="text-green-500 mr-4" aria-hidden="true"></SiWhatsapp>
          <span className="truncate">WhatsApp Dorothée (produit)</span>
        </a>
        <a
          href={`mailto:${email}`}
          className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
          target="_blank"
          rel="noreferrer"
        >
          <SiGmail className="text-red-500 mr-4" aria-hidden="true"></SiGmail>
          <span className="truncate">Envoyez un email</span>
        </a>
      </div>
    </section>
  );

  const banner = !hasPermission('pro') && (
    <div className="alert bg-primary text-white block text-xs rounded-none cursor-pointer" onClick={() => push('/user/subscription')}>
      🎉 Découvre nos formules et augmente ton chiffre d’affaires.{' '}
      <Link className="link font-bold" href="/user/subscription">
        Voir les formules
      </Link>
    </div>
  );

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={toggleSidebar}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" className="ml-1 flex items-center justify-center h-10 w-10 rounded-full" onClick={() => toggleSidebar(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="shrink-0 px-4 flex items-center gap-1">
                <Link href="/">
                  <Image className="w-auto" src="/logo/logo-wide-circled-orange.png" alt={siteName} width="240" height="60" />
                </Link>
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((category) => (
                    <section key={category.name}>
                      <h3 className="text-sm font-bold text-black uppercase tracking-wider mt-3 mb-2">{category.name}</h3>
                      <div>
                        {category.links
                          .filter(({ hidden }) => !hidden)
                          .map((item) => (
                            <Link
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                'group rounded-md py-2 px-2 flex items-center text-base font-medium'
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                  'mr-4 shrink-0 h-6 w-6'
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                              {item.badge && <span className="ml-auto badge badge-primary text-xs font-bold px-1">{item.badge}</span>}
                            </Link>
                          ))}
                      </div>
                    </section>
                  ))}
                </nav>
                <div className="mt-16">
                  {openToSource}
                  {banner}
                  {otherLinks}
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="shrink-0 w-14">{/* Dummy element to force sidebar to shrink to fit close icon */}</div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 z-20">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="show-scrollbar border-r border-gray-200 pt-5 flex flex-col flex-grow bg-white overflow-y-auto">
          <div className="shrink-0 px-4 flex items-center gap-1">
            <Link href="/">
              <Image className="w-auto" src="/logo/logo-wide-circled-orange.png" alt={siteName} width="240" height="60" />
            </Link>
          </div>
          <div className="flex-grow mt-5 flex flex-col">
            {['development', 'staging'].includes(process?.env?.NEXT_PUBLIC_SENTRY_ENV || '') && (
              <span className="w-full badge badge-ghost">{process?.env?.NEXT_PUBLIC_SENTRY_ENV}</span>
            )}
            <nav className="flex-1 px-2 pb-4 space-y-1">
              {navigation.map((category) => (
                <section key={category.name}>
                  <h3 className="text-xs font-bold text-black uppercase tracking-wider mt-3 mb-2">{category.name}</h3>
                  <div>
                    {category.links
                      .filter(({ hidden }) => !hidden)
                      .map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'relative group rounded-md py-2 px-2 flex items-center text-sm font-medium'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                          {item.badge && <span className="ml-auto badge badge-primary text-xs font-bold px-1">{item.badge}</span>}
                        </Link>
                      ))}
                  </div>
                </section>
              ))}
            </nav>
            {openToSource}
            {banner}
            {otherLinks}
          </div>
        </div>
      </div>

      <div className="md:pl-64">
        <BannerImpersonate />
        <div className="px-0 md:px-8">
          <header className="sticky top-0 z-20 shrink-0 h-16 bg-white border-b border-gray-200 flex">
            <button type="button" className=" px-4 text-gray-500 focus:outline-none md:hidden" onClick={() => toggleSidebar(true)}>
              <span className="sr-only">Open sidebar</span>
              <IconMenu className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 flex justify-between px-4 md:px-0">
              <div className="flex-1 flex">
                {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form> */}
              </div>
              <div className="ml-4 flex items-center md:ml-6 gap-2">
                {/* <button type="button" className="p-1 rounded-full text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
                {/* Profile dropdown */}
                <Link href="/user/subscription">
                  <MyPlanBadge />
                </Link>
                <NotificationCenter />
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs flex items-center text-sm rounded-full">
                      <span className="sr-only">Open user menu</span>
                      <Avatar name={user?.name} image={user?.image} />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) =>
                            item.onClick ? (
                              <a className={classNames(active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700')} onClick={item.onClick}>
                                {item.name}
                              </a>
                            ) : (
                              <Link href={item.href} className={classNames(active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700')}>
                                {item.name}
                              </Link>
                            )
                          }
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </header>

          <main className="py-3 sm:py-5 overflow-x-auto min-h-[70vh] px-0 sm:px-1">
            <Banner />
            <NotMemberBanner />
            {children}
          </main>
        </div>
      </div>
    </>
  );
}
