import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';

type AvatarProps = {
  name?: string;
  image?: string;
  size?: number;
  bordered?: boolean;
  borderColor?: 'primary' | 'secondary' | 'accent';
} & React.HTMLAttributes<HTMLDivElement>;

const Avatar: React.FC<AvatarProps> = ({ children, className, bordered, borderColor = 'primary', image, name, size = 10, ...props }) => {
  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center rounded-full overflow-hidden bg-gray-500',
        {
          'border-2 b border-solid': bordered,
          'border-primary': borderColor === 'primary',
          'border-secondary': borderColor === 'secondary',
          'border-accent': borderColor === 'accent',
        },
        `h-${size} w-${size}`,
        className
      )}
      {...props}
    >
      <span style={{ display: 'none' }}>{image}</span>
      {image ? (
        <Image src={image} alt={name || 'avatar'} width={100} height={100} />
      ) : (
        <span className="text-xs font-medium leading-none text-white">{name?.charAt(0).toUpperCase()}</span>
      )}
    </span>
  );
};

export default Avatar;
