import classNames from 'classnames';
import Avatar from 'modules/common/components/Avatar';
import Tooltip from 'modules/common/components/Tooltip';
import type { User } from 'modules/User/interfaces';
import React from 'react';
import { MdCampaign as IconAmbassador, MdBusiness as IconCompany, MdPersonSearch as IconHighlightedOpenToSource } from 'react-icons/md';
import { RxStarFilled as IconHighlighted } from 'react-icons/rx';

type HeadHunterAvatarProps = {
  user: User;
  withCompany?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const HeadHunterAvatar: React.FC<HeadHunterAvatarProps> = ({ children, className, withCompany = true, user, ...props }) => {
  const highlighted = user?.isTop;
  const highlightedOpenToSource = user?.openToSource;
  return (
    <div>
      <div className={classNames('relative flex items-center gap-1', className)} {...props}>
        <div className="relative flex items-center">
          <Avatar
            size={6}
            name={user?.name}
            image={user?.image}
            bordered={highlighted || highlightedOpenToSource}
            borderColor={highlightedOpenToSource ? 'accent' : highlighted ? 'primary' : undefined}
          />
          {highlighted && (
            <Tooltip
              placement="bottom"
              trigger={['click', 'hover']}
              overlayClassName="opacity-100"
              overlay={
                <div>
                  <strong>Top Headlinker</strong>
                </div>
              }
            >
              <span className="absolute -top-2 -left-2 cursor-help">
                <IconHighlighted className="text-primary h-4 w-4 stroke-white stroke-1" />
              </span>
            </Tooltip>
          )}
          {highlightedOpenToSource && (
            <Tooltip
              placement="bottom"
              trigger={['click', 'hover']}
              overlayClassName="opacity-100"
              overlay={
                <div>
                  <strong>Dispo pour sourcer !</strong>
                </div>
              }
            >
              <span className="absolute -top-2 -left-2 cursor-help">
                <IconHighlightedOpenToSource className="text-accent h-4 w-4 " />
              </span>
            </Tooltip>
          )}
        </div>
        {user?.name}
        {user?.ambassadorFor && (
          <Tooltip
            placement="bottom"
            trigger={['click', 'hover']}
            overlayClassName="opacity-100"
            overlay={
              <div>
                Ambassadeur pour <strong>{user?.ambassadorFor}</strong>
              </div>
            }
          >
            <span className="cursor-help">
              <IconAmbassador className="text-accent h-6 w-6 " />
            </span>
          </Tooltip>
        )}
      </div>
      {withCompany && (
        <small className="flex gap-1 items-center">
          <IconCompany />
          <span className="block truncate w-36">{user?.title}</span>
        </small>
      )}
    </div>
  );
};

export default HeadHunterAvatar;
