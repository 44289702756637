import { Loading } from '@/lib/components/ui/loading';
import { Popover, PopoverContent, PopoverTrigger } from '@/lib/components/ui/popover';
import classNames from 'classnames';
import { formatCurrency } from 'lib/helpers/strings';
import HeadHunterAvatar from 'modules/App/components/HeadHunterAvatar';
import { Link } from 'modules/i18n';
import { MdNotifications } from 'react-icons/md';
import useNotificationCenter from '../hooks/useNotificationCenter';
import { type Notification } from '../models/Notification';

const NotificationCenter: React.FC = () => {
  const { data, loading, error, markNotificationAsViewed } = useNotificationCenter({
    page: 1,
    limit: 10,
  });
  const notifications = data?.myNotifications?.items || [];
  const notificationCount = data?.myNotifications?.items?.filter((notification: Notification) => !notification.viewedAt)?.length || 0;
  const hasNotifications = notificationCount > 0;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="relative cursor-pointer">
          <MdNotifications
            className={classNames(
              'h-8 w-8 transition-colors',
              hasNotifications ? 'text-black hover:text-faded' : 'text-gray-400 hover:text-gray-600'
            )}
          />
          <div
            className={classNames('absolute -top-1 -right-1 h-4 w-4 rounded-full flex items-center justify-center', {
              'border border-gray-400 border-dashed animate-spin': loading,
              'border-2 border-primary': !loading && hasNotifications,
              hidden: !loading && !hasNotifications && !error,
            })}
          >
            {error ? (
              <span className="text-error text-xs">⚠️</span>
            ) : (
              !loading && (
                <span
                  className={classNames('text-xs font-medium', {
                    'text-primary': hasNotifications,
                    'text-gray-400': !hasNotifications,
                  })}
                >
                  {notificationCount > 9 ? '9+' : notificationCount}
                </span>
              )
            )}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-80 p-0 z-20">
        <div className="p-4 border-b">
          <h4 className="font-medium">
            Notifications <span className="badge">{notificationCount}</span>
          </h4>
        </div>
        {loading ? (
          <div className="p-4 text-center text-gray-500">
            <Loading size="sm" />
          </div>
        ) : error ? (
          <div className="p-4 text-center text-error">Une erreur est survenue</div>
        ) : notifications.length === 0 ? (
          <div className="p-4 text-center text-gray-500">Aucune notification</div>
        ) : (
          <div className="max-h-[300px] overflow-y-auto">
            <ul className="list-none">
              {notifications.map((notification: Notification) => {
                const object = notification.searchedProfile || notification.availableProfile;
                const url =
                  (notification.searchedProfile
                    ? `/searched-profiles/${object?._id}?from=notification`
                    : notification.availableProfile
                    ? `/available-profiles/${object?._id}?from=notification`
                    : undefined) ||
                  notification?.url ||
                  '#';
                const title =
                  notification?.title ||
                  (notification.type === 'new' ? (!!notification.searchedProfile ? 'Nouvelle mission' : 'Nouveau candidat Off-Market') : 'TODO');
                const subtitle = notification?.subtitle || object?.name;

                return (
                  <li key={notification._id} className={classNames('border-b list-none', { 'opacity-70': notification.viewedAt })}>
                    <Link
                      href={url}
                      className={classNames('p-2 flex flex-col hover:bg-gray-50 text-sm', {
                        'bg-primary/10': !notification.viewedAt,
                      })}
                      onClick={() => markNotificationAsViewed({ _id: notification._id })}
                    >
                      <span className="flex items-start justify-between w-full">
                        <span className="flex flex-col gap-2">
                          <span className={classNames('font-medium', { 'text-primary': !notification.viewedAt })}>
                            {title}
                            {!notification.viewedAt && <span className="ml-2 inline-block w-2 h-2 bg-primary rounded-full" />}
                          </span>
                          <span className="text-xs text-faded">{subtitle}</span>
                          {object?.createdBy && <HeadHunterAvatar user={object.createdBy} withCompany={false} />}
                        </span>
                        {object?.bounty && (
                          <span className="text-sm font-bold">
                            {formatCurrency({
                              locale: 'fr',
                              value: object.bounty,
                              precision: 2,
                              currency: object.currency || 'EUR',
                            })}
                          </span>
                        )}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default NotificationCenter;
