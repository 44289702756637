import RemovableBanner, { RemovableBannerButton } from '@/lib/components/ui/banner-removable';
import { useLocalStorageValue } from '@react-hookz/web';
import dayjs from 'dayjs';
import { gql, useQuery } from 'modules/GraphQL';
import useUser from 'modules/User/hooks/useUser';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React from 'react';

type BannerProps = React.HTMLAttributes<HTMLDivElement> & {
  // TODO
};

const COUNT_MY_SEARCHED_PROFILES = gql`
  query mySearchedProfiles {
    mySearchedProfiles {
      pageInfo {
        count
      }
    }
  }
`;
const COUNT_MY_AVAILABLE_PROFILES = gql`
  query myAvailableProfiles {
    myAvailableProfiles {
      pageInfo {
        count
      }
    }
  }
`;

const Banner: React.FC<BannerProps> = () => {
  const { hasPermission, user } = useUser();
  const pathname = usePathname();

  const showSearchedProfileBanner = pathname.includes('available-profiles');
  const showAvailableProfileBanner = pathname.includes('/searched-profiles') || pathname.includes('/my-missions') || pathname.includes('/candidates');

  const { value: searchedProfileHidden, set: setSearchedProfileHidden } = useLocalStorageValue<boolean>(`hide-searched_profile-banner`, {
    defaultValue: false,
  });
  const { value: availableProfileHidden, set: setAvailableProfileHidden } = useLocalStorageValue<boolean>(`hide-available_profile-banner`, {
    defaultValue: false,
  });

  const { data: mySearchedProfilesResults } = useQuery(COUNT_MY_SEARCHED_PROFILES, { skip: searchedProfileHidden || !showSearchedProfileBanner });
  const { data: myAvailableProfilesResults } = useQuery(COUNT_MY_AVAILABLE_PROFILES, { skip: availableProfileHidden || !showAvailableProfileBanner });

  React.useEffect(() => {
    if (showSearchedProfileBanner && mySearchedProfilesResults?.mySearchedProfiles?.pageInfo?.count > 0) {
      setSearchedProfileHidden(true);
    }
  }, [showSearchedProfileBanner, mySearchedProfilesResults?.mySearchedProfiles?.pageInfo?.count, setSearchedProfileHidden]);

  React.useEffect(() => {
    if (showAvailableProfileBanner && myAvailableProfilesResults?.myAvailableProfiles?.pageInfo?.count > 0) {
      setAvailableProfileHidden(true);
    }
  }, [showAvailableProfileBanner, myAvailableProfilesResults?.myAvailableProfiles?.pageInfo?.count, setAvailableProfileHidden]);

  if (!hasPermission('member')) {
    return null;
  }

  const shouldShowBanners = dayjs().isAfter(dayjs(user.createdAt2).add(14, 'day'))
    ? [
        searchedProfileHidden || mySearchedProfilesResults?.mySearchedProfiles?.pageInfo?.count > 0 || !showSearchedProfileBanner
          ? undefined
          : 'searched_profile',
        availableProfileHidden || myAvailableProfilesResults?.myAvailableProfiles?.pageInfo?.count > 0 || !showAvailableProfileBanner
          ? undefined
          : 'available_profile',
      ].filter(Boolean)
    : [];

  const showedBanner = shouldShowBanners[0];

  return (
    <>
      {showedBanner === 'searched_profile' && (
        <RemovableBanner
          keyName="searched_profile"
          position="top"
          title="Tu n'as pas encore posté de mission!"
          description="Sais-tu que sur Headlinker, tu peux publier une mission et que les autres recruteurs te proposeront des candidats pour celle-ci ?"
          className="border-info border-2 border-dashed  relative mb-5"
        >
          <RemovableBannerButton variant={'primary'} href="/my-missions">
            Je poste une mission
          </RemovableBannerButton>
        </RemovableBanner>
      )}
      {showedBanner === 'available_profile' && (
        <RemovableBanner
          keyName="available_profile"
          position="top"
          title="Tu n'as pas encore proposé de candidat!"
          description="Sais-tu que sur Headlinker, tu peux publier un de tes candidats en vivier et que les autres recruteurs lui proposeront une mission ?"
          className="border-info border-2 border-dashed  relative mb-5"
        >
          <RemovableBannerButton variant={'primary'} href="/my-missions">
            Je partage un candidat
          </RemovableBannerButton>
        </RemovableBanner>
      )}
    </>
  );
};

export default dynamic(() => Promise.resolve(Banner as React.ComponentType<BannerProps>), { ssr: false });

// export default Banner;
