'use client';
import { useLocalStorageValue } from '@react-hookz/web';
import { Button } from 'lib/components/ui/button';
import { cn } from 'lib/utils';
import { event, track } from 'modules/Analytics';
import React, { createContext, useContext } from 'react';

type RemovableBannerProps = React.HTMLAttributes<HTMLDivElement> & {
  keyName: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  position?: 'top' | 'bottom';
};

type RemovableBannerContextType = {
  setRemovableBannerHidden: (hidden: boolean) => void;
  keyName: string;
};

const RemovableBannerContext = createContext<RemovableBannerContextType>({
  setRemovableBannerHidden: () => {},
  keyName: '',
});

export const RemovableBannerButton = ({ onClick, children, ...props }: React.ComponentProps<typeof Button>) => {
  const { setRemovableBannerHidden, keyName } = useContext(RemovableBannerContext);
  return (
    <Button
      onClick={(...args) => {
        setRemovableBannerHidden(true);
        event({ action: `banner-${keyName}-click`, category: 'banner' });
        track(`banner.${keyName}.clicked`);
        return onClick?.(...args);
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export const RemovableBanner: React.FC<RemovableBannerProps> = ({ className, title, description, position = 'top', keyName, children, ...props }) => {
  const { value: hidden, set } = useLocalStorageValue<boolean>(`hide-${keyName}-banner`, { defaultValue: false });

  if (hidden) return null;

  return (
    <RemovableBannerContext.Provider value={{ setRemovableBannerHidden: set, keyName }}>
      <div
        suppressHydrationWarning
        className={cn(
          'shadow-lg fixed start-0 z-10 flex flex-col justify-between w-full p-4 border-b border-gray-200 md:flex-row bg-gray-50 dark:bg-gray-700 dark:border-gray-600',
          position === 'bottom' ? 'bottom-0' : 'top-0',
          className
        )}
        {...props}
      >
        <div className="mb-4 md:mb-0 md:me-4">
          {title && <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">{title}</h2>}
          {description && <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">{description}</p>}
        </div>
        <div className="flex items-center flex-shrink-0 gap-2">
          {children}
          <button
            type="button"
            onClick={() => {
              event({ action: `banner-${keyName}-close`, category: 'banner' });
              track(`banner.${keyName}.closed`);
              set(true);
            }}
            className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span className="sr-only">Close banner</span>
          </button>
        </div>
      </div>
    </RemovableBannerContext.Provider>
  );
};

export default RemovableBanner;
