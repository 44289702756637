import { useSearchParams } from 'next/navigation';
import { useQueryState } from 'nuqs';

type useQueryStateOptions<Type> = {
  defaultValue?: Type[];
  keepQueryParam?: boolean;
};

const useArrayQueryState = <Type extends string>(name: string, { defaultValue = [], keepQueryParam = false }: useQueryStateOptions<Type> = {}) => {
  const [items, setItems] = useQueryState<Type[]>(name, {
    defaultValue,
    parse: (value) => (value ? (value.split(',') as Type[]) : []),
    serialize: (value) => value?.join(',') || '',
  });
  const searchParams = useSearchParams();

  const rawItems = searchParams.get(name);

  const set = (newItems: Type[]) => {
    setItems(keepQueryParam ? newItems : newItems.length > 0 ? newItems : null);
  };

  const reset = () => setItems(null);

  const add = (name: Type) => {
    if (!items.includes(name)) {
      setItems((prevItems) => {
        const newItems = [...prevItems, name].filter(Boolean);
        if (keepQueryParam) {
          return newItems;
        }
        return newItems.length > 0 ? newItems : null;
      });
    }
  };

  const remove = (name: Type) => {
    setItems((prevItems) => {
      const newItems = prevItems.filter((accordion) => accordion !== name);

      if (keepQueryParam) {
        return newItems;
      }
      // If no items are left, remove the query param by setting null
      return newItems.length > 0 ? newItems : null;
    });
  };

  const has = (name: Type) => items.includes(name);

  const toggle = (name: Type) => (has(name) ? remove(name) : add(name));

  return { add, remove, has, toggle, set, items, exists: typeof rawItems === 'string', reset };
};

export default useArrayQueryState;
