import { useDebouncedEffect } from '@react-hookz/web';
import React, { useId, useState } from 'react';

import { Loading } from '@/lib/components/ui/loading';
import { Combobox, ComboboxInput, ComboboxList, ComboboxOption, ComboboxOptionText, ComboboxPopover } from '@reach/combobox';
import { MdClear, MdErrorOutline, MdSearch } from 'react-icons/md';
type DefaultOption = Record<string, any>;

export type AutocompleteProps<Option extends DefaultOption> = Omit<React.ComponentProps<typeof Combobox>, 'children' | 'onSelect'> & {
  fetchFn: (query: string) => Promise<Option[]>;
  debounceTime?: number;
  minCharThreshold?: number;
  onSelect: (option: Option) => void;
  onClear?: () => void;
  nativeInputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'>;
  getOptionLabel?: (data: { option: Option; result: React.ReactNode }) => React.ReactNode;
  getOptionValue: (option: Option) => string;
  disableFirstFetch?: boolean;
};

const Autocomplete = <Option extends DefaultOption>({
  fetchFn,
  nativeInputProps,
  debounceTime = 300,
  minCharThreshold = 0,
  getOptionLabel,
  getOptionValue,
  onSelect,
  onClear,
  defaultValue,
  disableFirstFetch = false,
  ...props
}: AutocompleteProps<Option>) => {
  const [inputValue, setInputValue] = useState(defaultValue ? `${defaultValue}` : '');
  const [defaultValueSet, setDefaultValueSet] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [error, setError] = useState<string>();
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const generatedId = useId();

  useDebouncedEffect(
    () => {
      (async () => {
        if (!(inputValue?.length >= minCharThreshold && !selectedValue && !error)) {
          setLoading(false);
          return;
        }
        try {
          if (disableFirstFetch && defaultValue && !defaultValueSet) {
            setDefaultValueSet(true);
            return;
          }
          const results = await fetchFn(inputValue);
          if (results.length > 0 && defaultValue && !defaultValueSet) {
            // a default value is present so fetch the result and select it
            setDefaultValueSet(true);
            const defaultSelectedIndex = results.findIndex((item) => getOptionValue(item) === defaultValue);

            const option = results[defaultSelectedIndex || defaultSelectedIndex === -1 ? defaultSelectedIndex : 0];
            const optionValue = getOptionValue(option);
            setSelectedValue(optionValue);
            onSelect?.(option);
          } else {
            setOptions(results);
          }
        } catch (error: any) {
          console.error('Error fetching data:', error);
          setError(error.toString());
        } finally {
          setLoading(false);
        }
      })();
    },
    [inputValue, selectedValue, error, disableFirstFetch],
    debounceTime
  );

  const CloseSearchIcon = inputValue ? MdClear : MdSearch;

  return (
    <Combobox
      onSelect={(optionValue) => {
        const option = options.find((option) => getOptionValue(option) === optionValue);
        if (!option) {
          return;
        }

        setSelectedValue(optionValue);
        setOptions([]);
        onSelect?.(option);
      }}
      {...props}
    >
      <div style={{ position: 'relative' }}>
        <ComboboxInput
          id={generatedId}
          value={selectedValue || inputValue}
          onChange={(event) => {
            setSelectedValue('');
            setError(undefined);
            setLoading(true);
            setInputValue(event.target.value);
          }}
          {...nativeInputProps}
          style={{
            ...nativeInputProps?.style,
            textOverflow: 'ellipsis',
            paddingRight: '2.5rem',
            width: '100%',
          }}
        />

        {error && (
          <MdErrorOutline
            height={12}
            width={12}
            color="red"
            title={error}
            style={{
              position: 'absolute',
              top: '0.75rem',
              bottom: '0.75rem',
              margin: 'auto',
              right: 'calc(16px + 1rem)',
            }}
          />
        )}
        {loading ? (
          <Loading
            size="xs"
            style={{
              position: 'absolute',
              top: '0.75rem',
              bottom: '0.75rem',
              margin: 'auto',
              right: '0.625rem',
              height: '16px',
            }}
          />
        ) : (
          <CloseSearchIcon
            style={{
              position: 'absolute',
              top: '0.75rem',
              right: '0.5rem',
              bottom: '0.75rem',
              margin: 'auto',
              pointerEvents: inputValue ? 'all' : 'none',
              cursor: inputValue ? 'pointer' : 'default',
            }}
            onClick={() => {
              setSelectedValue('');
              inputValue ? setInputValue('') : undefined;
              setOptions([]);
              onClear?.();
            }}
          />
        )}
      </div>

      {options.length > 0 && (
        <ComboboxPopover>
          <ComboboxList>
            {options.map((option, index) => {
              const optionValue = getOptionValue(option);
              const optionLabel = getOptionLabel ? getOptionLabel({ option, result: <ComboboxOptionText /> }) : <ComboboxOptionText />;
              return (
                <ComboboxOption key={`${optionValue}_${index}`} value={optionValue}>
                  {optionLabel}
                </ComboboxOption>
              );
            })}
          </ComboboxList>
        </ComboboxPopover>
      )}
    </Combobox>
  );
};

export default Autocomplete;
